import Item from '../types/models/item'
import Server from '../types/models/server'
import {request} from './index'
import TokenConfig from './token'

const getServers = (item?: Item["name"], page?: number) => {
    return request.get('servers', {headers: TokenConfig().headers, params: {
        page: page,
        item: item
    }});
}

const getServer = (id: number) =>
{
    return request.get(`servers/${id}`, {headers: TokenConfig().headers});
}

const addServer = (server: Server, info: string) =>
{
    const params = {
        name: server.name,
        short_name: server.short_name,
        limit: server.limit,
        provider: server.provider,
        country: server.country,
        info: info
    }
    return request.post('servers',  params ,{headers: TokenConfig().headers});
}

const updateServer = (server: Server, info: string) =>
{
    return request.put(`servers/${server.id}`, {
        name: server.name,
        shortName: server.short_name,
        limit: server.limit,
        provider: server.provider,
        country: server.country,
        info: info
    } , { headers: TokenConfig().headers })
}

const deleteServer = (id: number) =>
{
    return request.delete(`servers/${id}`, TokenConfig());
}

const sendServerMessage = (id: number, message: string) =>
{
    return request.post(`servers/${id}/messages/send`, {
        message: message
    } , { headers: TokenConfig().headers })
}

export { getServers, getServer, addServer, updateServer, deleteServer, sendServerMessage};