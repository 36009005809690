import { AppDispatch, AppThunk } from '../store'
import { ActionType } from '../types/actions/server'
import {getServers, addServer, updateServer, deleteServer, getServer, sendServerMessage} from '../api/serverAPI'
import Server from '../types/models/server'
import Item from '../types/models/item'

const addServerAction = (server: Server, info: string): AppThunk => (dispatch: any) =>
{
    dispatch({
        loading: true,
        type: ActionType.SERVER_LOADING
    })
    addServer(server, info).then((response) => {
        dispatch({
            loading: false,
            type: ActionType.SERVER_POST,
            success: response.data.success,
            errors: []
        })
    }).catch((exception) => {
        dispatch({
            loading: false,
            type: ActionType.SERVER_ERROR,
            errors: [exception.response.data.errors ?? exception.message],
            success: false
        })
    })
}

const getServerAction = (id: number): AppThunk => (dispatch: any) =>
{
    dispatch({
        loading: true,
        type: ActionType.SERVER_LOADING
    })
    getServer(id).then((response) => {
        dispatch({
            loading: false,
            type: ActionType.SERVER_GET,
            data: [response.data.data],
            meta: response.data.meta
        })
    }).catch((exception) => {
        dispatch({
            loading: false,
            type: ActionType.SERVER_ERROR,
            errors: [exception.message]
        })
    })
}

const getServersAction = (item?: Item["name"], page?: number): AppThunk => (dispatch: any) =>
{
    dispatch({
        loading: true,
        type: ActionType.SERVER_LOADING
    })
    getServers(item, page).then((response) => {
        dispatch({
            loading: false,
            type: ActionType.SERVER_GETS,
            data: response.data.data,
            meta: response.data.meta
        })
    }).catch((exception) => {
        dispatch({
            loading: false,
            type: ActionType.SERVER_ERROR,
            errors: [exception.message]
        })
    })
}

const updateServersAction = (server: Server, info: string): AppThunk => (dispatch: any) =>
{
    dispatch({
        loading: true,
        type: ActionType.SERVER_LOADING
    })
    updateServer(server, info).then((response) => {
        dispatch({
            loading: false,
            type: ActionType.SERVER_PUT,
            success: response.data.success,
            errors: []
        })
    }).catch((exception) => {
        dispatch({
            loading: false,
            type: ActionType.SERVER_ERROR,
            errors: [exception.response.data.errors ?? exception.message],
            success: false
        })
    })
}

const deleteServersAction = (id: number): AppThunk => (dispatch: any) =>
{
    dispatch({
        loading: true,
        type: ActionType.SERVER_LOADING
    })
    deleteServer(id).then((response) => {
        dispatch({
            loading: false,
            type: ActionType.SERVER_DELETE,
            success: response.data.success,
            errors: []
        })
    }).catch((exception) => {
        dispatch({
            loading: false,
            type: ActionType.SERVER_ERROR,
            errors: [exception.response.data.errors ?? exception.message],
            success: false
        })
    })
}

const sendServerMessageAction = (id: number, message: string): AppThunk<Promise<{ success: boolean; message: string }>> => async (dispatch, getState) => {
    dispatch({
        loading: true,
        type: ActionType.SERVER_LOADING,
    });

    try {
        const response = await sendServerMessage(id, message);

        const result = { success: true, message: response.data.message || 'Message sent successfully!' };

        dispatch({
            loading: false,
            type: ActionType.SERVER_POST,
            success: result.success,
            errors: [],
        });

        return result;
    } catch (exception: any) {
        const errorMessage = exception.response?.data?.errors ?? exception.message;

        const result = { success: false, message: errorMessage };

        dispatch({
            loading: false,
            type: ActionType.SERVER_ERROR,
            errors: [errorMessage],
            success: false,
        });

        return result;
    }
};

export { getServersAction, getServerAction, addServerAction, updateServersAction, deleteServersAction, sendServerMessageAction }