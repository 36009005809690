import {useEffect, useState} from 'react'
import {Helmet} from 'react-helmet'

import useVPNBot from '../../../hooks/useVPNBot'
import Spinner from '../../Spinner'
import {Button, Col, Form, Input, notification, Row, Select} from "antd";
import {SelectProps} from "rc-select/lib/Select";

const {TextArea} = Input

const AnalyticsTab = () => {

    //-------------------------------------------------------------------------------
    //                                  STATES
    //-------------------------------------------------------------------------------

    const [form] = Form.useForm()
    const {useServer} = useVPNBot()
    const {getServers, sendMessageOnServer, servers, loading} = useServer
    const [serverOptions, setServerOptions] = useState<SelectProps['options']>([])

    //-------------------------------------------------------------------------------
    //                                  EFFECTS
    //-------------------------------------------------------------------------------

    useEffect(() => {
        getServers()
    }, [])

    useEffect(() => {
        const mappedServerOptions = servers?.map((server) => {
            return {
                value: server.id,
                label: server.name,
            }
        })

        setServerOptions(mappedServerOptions || [])
    }, [servers]);

    const handleApply = async (values: any) => {
        const {serverId, message} = values;
        const result = await sendMessageOnServer(serverId, message);
        if (result.success) {
            notification.success({
                message: 'Success',
                description: result.message,
            });
            form.resetFields();
        } else {
            notification.error({
                message: 'Error',
                description: result.message
            });
        }
    }

    const handleSource: ((name: string, value: string) => any) = () => {
    }

    //-------------------------------------------------------------------------------
    //                                  RENDER
    //-------------------------------------------------------------------------------

    const renderContent = () => {
        if (loading) {
            return (
                <div className='flex justify-items-center items-center flex-col w-full'>
                    <Spinner/>
                </div>
            )
        }

        if (!servers) {
            return (
                <div className='flex justify-items-center items-center flex-col w-full'>
                    No servers found
                </div>
            )
        }

        return (
            <div className="flex flex-col gap-16">
                <Form
                    name="message-form"
                    autoComplete="off"
                    layout="vertical"
                    style={{maxWidth: 600}}
                    id="message-form"
                    form={form}
                    onFinish={handleApply}
                >
                    <Row>
                        <Col span={24} className="pr-1">
                            <Form.Item
                                label="Please select server"
                                name="serverId"
                                rules={[{required: true, message: 'Please select a server'}]}
                            >
                                <Select options={serverOptions} onChange={(value: any) => handleSource('type', value)}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} className="pr-1">
                            <Form.Item
                                label="Message"
                                name="message"
                                rules={[{required: true, message: 'Please enter a message'}]}
                            >
                                <TextArea rows={4}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={4} offset={21}>
                            <Form.Item label={null}>
                                <Button type="primary" htmlType="submit" className='self-end'>
                                    Send
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }

    return (
        <>
            <Helmet>
                <title>Message - VPNBot Admin</title>
            </Helmet>
            {renderContent()}
        </>
    )
}

export default AnalyticsTab
